<template>
  <b10-base>
    <v-text-field
      v-model="form.descripcion_sistema"
      clearable
      flat
      label="Nombre de la instalación/servicio"
      :append-icon="form.descripcion_sistema ? null : $vuetify.icons.values.equals"
      @click:append="clickCopyTituloADescripcionSistema"
    />
    <v-text-field
      v-model="form.titulo"
      clearable
      flat
      label="Título"
      :append-icon="form.titulo ? null : $vuetify.icons.values.equals"
      @click:append="clickCopyDescripcionSistemaATitulo"
    />
    <b10-autocomplete
      v-model="form.cliente_direccion"
      :items="direcciones"
      item-text="descripcion"
      label="Dirección"
      return-object
      clearable
    />
    <b10-autocomplete-alert
      v-if="form.cliente_direccion"
    >
      {{ form.cliente_direccion.descripcion }}
    </b10-autocomplete-alert>
    <b10-autocomplete
      v-model="form.idagente_comercial"
      :items="agentesComerciales"
      item-value="idagente_comercial"
      item-text="nombre"
      label="Agente"
      clearable
    />
    <b10-autocomplete
      v-model="form.idcanal_comunicacion"
      :items="canalesComunicacion"
      item-value="idcanal_comunicacion"
      item-text="descripcion"
      label="Canal de comunicación"
      clearable
    />
    <b10-autocomplete
      v-model="form.idcanal_conocimiento"
      :items="canalesConocimiento"
      item-value="idcanal_conocimiento"
      item-text="descripcion"
      label="Canal de conocimiento"
      clearable
    />
    <b10-autocomplete
      v-model="form.tipo_riesgo"
      :items="tiposRiesgo"
      item-text="descripcion"
      label="Tipo de riesgo"
      return-object
      clearable
      @change="tipoRiesgoSelected"
    />
    <b10-autocomplete
      v-model="form.idgrado_seguridad"
      :items="gradosSeguridad"
      item-value="idgrado_seguridad"
      item-text="descripcion"
      label="Grado de seguridad"
      clearable
    />
    <b10-subheader
      label="Formas y condiciones de pago"
    />
    <b10-autocomplete
      v-model="form.idfpago_sistema"
      :items="formasPago"
      item-value="idforma_pago"
      item-text="descripcion"
      label="Forma de pago (artículos y mano de obra)"
      clearable
    />
    <b10-autocomplete
      v-model="form.idcondicion_pago"
      :items="condicionesPago"
      item-value="idcondicion_pago"
      item-text="descripcion"
      label="Condición de pago  (artículos y mano de obra)"
      clearable
    />
    <b10-autocomplete
      v-model="form.idfpago_cuotas"
      :items="formasPago"
      item-value="idforma_pago"
      item-text="descripcion"
      label="Forma de pago (cuotas)"
      clearable
    />
    <b10-autocomplete
      v-model="form.idcondicion_pago_cuota"
      :items="condicionesPago"
      item-value="idcondicion_pago"
      item-text="descripcion"
      label="Condición de pago (cuotas)"
      clearable
    />
    <b10-subheader
      label="Comentarios"
    />
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
    <b10-textarea
      v-model="form.notas_internas"
      label="Notas internas"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './PresupuestocliEditFormData'
import { PRESUPUESTOCLI } from '@/utils/consts'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
  data () {
    return {
      form: {
        descripcion_sistema: null,
        titulo: null,
        cliente_direccion: null,
        idagente_comercial: null,
        idcanal_comunicacion: null,
        idcanal_conocimiento: null,
        tipo_riesgo: null,
        idgrado_seguridad: null,
        idfpago_sistema: null,
        idcondicion_pago: null,
        idfpago_cuotas: null,
        idcondicion_pago_cuota: null,
        observaciones: null,
        notas_internas: null,
      },
      canalesComunicacion: [],
      canalesConocimiento: [],
      tiposRiesgo: [],
      gradosSeguridad: [],
      formasPago: [],
      condicionesPago: [],
      agentesComerciales: [],
      direcciones: [],
    }
  },
  computed: {
    estudioTecnicoEditable () {
      return (
        this.form?.idest_presupuestocli &&
        PRESUPUESTOCLI.clasificacionEstadosHabilitadosParaEstudioTecnico.includes(this.form?.idclasificacion_estado)
      )
    },
  },
  async created () {
    const resp = await Data.selectLookups(this, this.id)
    this.agentesComerciales = resp.data.selectAgenteComercial.result.dataset
    this.canalesComunicacion = resp.data.selectCanalComunicacion.result.dataset
    this.canalesConocimiento = resp.data.selectCanalConocimiento.result.dataset
    this.tiposRiesgo = resp.data.selectTipoRiesgo.result.dataset
    this.gradosSeguridad = resp.data.selectGradoSeguridad.result.dataset
    this.formasPago = resp.data.selectFormaPago.result.dataset
    this.condicionesPago = resp.data.selectCondicionPago.result.dataset
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      const resp = await Data.selectLookupsFormData(
        this,
        formData.idcliente,
        formData.idcliente_potencial,
        formData.idtipo_riesgo
      )
      this.direcciones = resp.data.selectClienteDireccion.result.dataset
      formData.tipo_riesgo = resp.data.selectTipoRiesgo.result.dataset[0]
      formData.cliente_direccion = _.find(this.direcciones, { 'idcliente_direccion': formData.idcliente_direccion })
      this.form = formData
    },
    tipoRiesgoSelected (value) {
      if (value) {
        this.$set(this.form, 'idgrado_seguridad', value.idgrado_seguridad)
      } else {
        this.$set(this.form, 'idgrado_seguridad', null)
      }
    },
    clickCopyTituloADescripcionSistema () {
      this.$set(this.form, 'descripcion_sistema', this.form.titulo)
    },
    clickCopyDescripcionSistemaATitulo () {
      this.$set(this.form, 'titulo', this.form.descripcion_sistema)
    },
  },
}
</script>
